import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('@/components/MainPage'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/components/Policy/PrivacyPolicy.vue'),
  },
  {
    path: '/exchange-policy',
    name: 'ExchangePolicy',
    component: () => import('@/components/Policy/ExchangePolicy.vue'),
  },
  {
    path: '/public-offert',
    name: 'PublicOffert',
    component: () => import('@/components/Policy/PublicOffert.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/components/Order.vue')
  },
  {
    path: '/transaction',
    name: 'TransactionComponent',
    component: () => import('@/components/TransactionComponent.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
