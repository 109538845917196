<!-- eslint-disable -->
<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderComponent from '@/components/HeaderComponent.vue';
import Footer from '@/components/Footer.vue';

const route = useRoute();
const showFooter = computed(() => route.path !== '/transaction');
</script>

<template>
  <HeaderComponent />
  <router-view />
  <Footer v-if="showFooter" />
</template>

<style>
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.app__window {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 80px;
  border-radius: 10px;

  @media (max-width: 928px) {
    padding: 32px 20px;
  }
}
</style>
