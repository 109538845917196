<template>
  <div class="burger-menu">
    <div class="burger-menu__container">
      <div
        class="burger-menu__close-btn"
        @click="closeMenu"
      >
        <Icon name="closeBurgerBtn" />
      </div>

      <div class="burger-menu__buttons">
        <NavBar />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/common/Icon';
import NavBar from '@/components/NavBar';

export default {
  components: { Icon, NavBar },
  emits: ['close-menu'],

  setup(props, {emit}) {
    const closeMenu = () => emit('close-menu');

    return { closeMenu };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";
@import "../assets/mixins";

.burger-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 36rem;
  width: 25rem;

  @include respond-to($tablet) {
    display: block;
  }

  &__container {
    backdrop-filter: blur(0.75rem);
    z-index: 1000;
    padding: 2rem;
    position: relative;
    left: -0.2rem;
    height: 30rem;
    width: 25rem;
    background-color: $burger-menu-bg;
    box-shadow: 0.5rem 0.5rem 2rem rgba(8, 31, 43, 0.2);
  }

  &__buttons {
    padding-top: 1.4rem;
  }
}
</style>
