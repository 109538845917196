<script setup>
import logo_img from '@/../public/logo.svg'
import config from '../../../config/config.json';

const logo = config?.logo_url ? config.logo_url : logo_img;

const org_number = config?.org_number || '-';
const reg_name = config?.reg_name || '-';
const address = config?.address || '-';
const address2 = config?.address2 || '-';
const address3 = config?.address3 || '-';
const tel = config?.phone || '-';
const mail = config?.email || '-';
const name = config?.name || 'website';
</script>

<template>
  <div class="footer-contacts">
    <router-link class="footer-contacts__logo"
to="/">
      <img
          :src="logo"
          class="footer-contacts__logo-icon"
          alt="LOGO"
      >
    </router-link>

    <div class="footer-contacts__des-wrp-one">
      <p class="footer-contacts__des">
        {{ `© ${name} - якісні гантелі` }}&nbsp;
      </p>
      <p class="footer-contacts__des">
        {{ 'будь-якого розміру' }}
      </p>
    </div>

    <div class="footer-contacts__des-wrp">
      <div class="footer-contacts__des-link-wrp">
        <p
          class="footer-contacts__des">
        {{ reg_name }}
      </p>
      </div>
      <div class="footer-contacts__des-link-wrp">
        <p
            class="footer-contacts__des">
          {{ `Код: ${org_number}` }}
        </p>
      </div>
      <div class="footer-contacts__des-link-wrp">
        <p class="footer-contacts__des">
          {{ `Адреса: ${address}` }}&nbsp;
        </p>
        <p class="footer-contacts__des">
          {{ address2 }}
        </p>
        <p class="footer-contacts__des">
          {{ address3 }}
        </p>
      </div>
      <div class="footer-contacts__des-link-wrp2">
        <p class="footer-contacts__des">
          {{ 'Телефон:' }}&nbsp;
        </p>
        <a :href="`tel:${tel}`"
class="footer-contacts__des-link">
          {{ tel }}
        </a>
      </div>

      <div class="footer-contacts__des-link-wrp">
        <a :href="`mailto:${mail}`"
class="footer-contacts__des-link">
          {{ mail }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../assets/_mixins";
@import "../../assets/_variables";

.footer-contacts {
  &__logo {
    display: flex;
    align-items: center;
  }

  &__logo-icon {
    width: 120px;
    height: 32px;
    //height: 50px;
  }

  &__logo-text {
    font-weight: 700;
    font-size: 14px;
    color: $col-text;
    margin-left: 12px;
  }

  &__des, &__des-link {
    color: #9D9E9A;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat', 'sans-serif';
  }

  &__des-link-wrp2 {
    display: flex;
  }

  &__des-wrp, &__des-wrp-one {
    margin-top: 30px;

    @include respond-to($tablet) {
      margin-top: 18px;
    }
  }

  &__des-wrp-one {
    @include respond-to($tablet) {
      display: flex;
    }
  }
}
</style>